<template>
  <Home />
  <Formato />
  <Calendario />
  <Regras />
  <Footer />
</template>

<script>
import gsap from 'gsap';
import Home from './components/Home.vue'
import Formato from './components/Formato.vue'
import Calendario from './components/Calendario.vue'
import Regras from './components/Regras.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Home,
    Formato,
    Calendario,
    Regras,
    Footer
  },
  mounted() {
    this.scrollAnimation()
  },
  methods: {
    scrollAnimation() {
      gsap.from("#hashtag-home", {
        scrollTrigger: {
          trigger: "#hashtag-home",
          start: "top bottom",
        },
        duration: 1,
        opacity: 0,
        y: 100
      });
      gsap.from("#titulo-home", {
        scrollTrigger: {
          trigger: "#titulo-home",
          start: "top bottom",
        },
        duration: 1,
        opacity: 0,
        y: 100,
        delay: .2
      });
      gsap.from("#description-home", {
        scrollTrigger: {
          trigger: "#titulo-home",
          start: "top bottom",
        },
        duration: 1,
        opacity: 0,
        y: 100,
        delay: .4
      });
      gsap.from("#acompanhe-home", {
        scrollTrigger: {
          trigger: "#acompanhe-home",
          start: "top bottom",
        },
        duration: 1,
        opacity: 0,
        y: 100
      });
      gsap.from("#flakes-power", {
        scrollTrigger: {
          trigger: "#flakes-power",
          start: "top bottom",
        },
        duration: 1,
        opacity: 0,
        x: 100
      });
      gsap.from("#titulo-calendario", {
        scrollTrigger: {
          trigger: "#titulo-calendario",
          start: "top bottom",
        },
        duration: 1,
        opacity: 0,
        y: 100,
      });
      gsap.from("#calendario-img", {
        scrollTrigger: {
          trigger: "#calendario-img",
          start: "top bottom",
        },
        duration: 1,
        opacity: 0,
        y: 100,
        delay: .2
      });
      gsap.from(".legendas", {
        scrollTrigger: {
          trigger: ".legendas",
          start: "top bottom",
        },
        duration: 1,
        opacity: 0,
        y: 100,
      });
      gsap.from("#regras-logo", {
        scrollTrigger: {
          trigger: "#regras-logo",
          start: "top bottom",
        },
        duration: 1,
        opacity: 0,
        y: 100,
      });
      gsap.from("#btn-regras", {
        scrollTrigger: {
          trigger: "#btn-regras",
          start: "top bottom",
        },
        duration: 1,
        y: 100,
        delay: .2,
        opacity: 0,
      });
      gsap.from("#acompanhe-regras", {
        scrollTrigger: {
          trigger: "#acompanhe-regras",
          start: "top bottom",
        },
        duration: 1,
        opacity: 0,
        delay: .4,
        y: 100,
      });
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Baloo+Tamma+2:wght@400;700;800&display=swap');

@font-face {
  font-family: "Morganite";
  src: local("Morganite"),
   url(./assets/Morganite/Morganite-ExtraBold.ttf) format("truetype"),
   url(./assets/Morganite/Morganite-SemiBold.ttf) format("truetype");
}
/* $laranja: #F56D5A;
$azul: #00D3FF; */

#app {
  font-family: 'Baloo Tamma 2', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.btn-regras {
  font-size: 33px;
  font-weight: 800;
  color: #FFFFFF;
  text-transform: uppercase;
  border: 0;
  border-radius: 10px;
  padding: 22px 30px 15px 30px;
  background-color: transparent;
  line-height: 100%;
  transition: 0.3s;
  background: transparent linear-gradient(90deg, #F38456 0%, #FF2469 100%) 0% 0% no-repeat padding-box;
  text-decoration: none;
  display: block;
  text-align: center;
  &:hover {
    color: #FFFFFF;
    transform: scale(1.1);
  }
}
</style>
