<template>
  <div class="calendario" id="calendario">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="calendario-titulo text-center" id="titulo-calendario">
            CALENDÁRIO OFICIAL
          </div>
        </div>
      </div>
      <div class="row calendarios">
        <div class="col-12 text-center">
          <img src="../assets/julho.png" id="calendario-img" alt="calendario julho" class="img-fluid">
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="legendas p-4 my-3">
            <div class="row">
              <div class="col-12 col-md-4 legenda">
                <div class="chip qualifiers-pc"></div>
                <div class="texto">
                  <div>Classificatórias - PC</div>
                  <div>Round 1 - 14h00 BRT</div>
                  <div>Round 2 - 17h30 BRT</div>
                </div>
              </div>
              <div class="col-12 col-md-4 legenda">
                <div class="chip qualifiers-console"></div>
                <div class="texto">
                  <div>Classificatórias - Console</div>
                  <div>Round 1 - 14h00 BRT</div>
                  <div>Round 2 - 17h30 BRT</div>
                </div>
              </div>
              <div class="col-12 col-md-4 legenda">
                <div class="chip heats-pc"></div>
                <div class="texto">
                  <div>Heats - PC</div>
                  <div>Round 1 - 14h00 BRT</div>
                  <div>Round 2 - 17h30 BRT</div>
                </div>
              </div>
              <div class="col-12 col-md-4 legenda">
                <div class="chip heats-console"></div>
                <div class="texto">
                  <div>Heats - Console</div>
                  <div>Round 1 - 14h00 BRT</div>
                  <div>Round 2 - 17h30 BRT</div>
                </div>
              </div>
              <div class="col-12 col-md-4 legenda">
                <div class="chip finais-pc"></div>
                <div class="texto">
                  <div>Finais - PC</div>
                  <div>Round 1 - TBD</div>
                  <div>Round 2 - TBD</div>
                </div>
              </div>
              <div class="col-12 col-md-4 legenda">
                <div class="chip finais-console"></div>
                <div class="texto">
                  <div>Finais - Console</div>
                  <div>Round 1 - 14h00 BRT</div>
                  <div>Round 2 - 14h00 BRT</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Calendario',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$qualifiers-pc: #F0A500;
$qualifiers-console: #0ED5E1;
$heats-pc: #E45826;
$heats-console: #5454D4;
$finais-pc: #71A84A;
$finais-console: #E4D126;

.calendario {
  background-image: url(../assets/calendario-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding: 120px 0;
  .calendario-titulo {
    font-size: 100px;
    font-family: "Morganite";
    font-weight: 600;
    color: #FFFFFF;
    line-height: 100%;
  }
  .legendas {
    background-color: #FFFFFF;
    .legenda {
      display: flex;
      align-items: center;
      margin: 10px 0;
      .chip {
        width: 23px;
        height: 23px;
        border-radius: 100%;
      }
      .texto {
        padding-top: 5px;
        line-height: 100%;
        font-size: 20px;
        font-weight: 800;
        padding-left: 15px;
      }
      .qualifiers-pc {
        background-color: #F0A500;
      }
      .qualifiers-console {
        background-color: #0ED5E1;
      }
      .heats-pc {
        background-color: #E45826;
      }
      .heats-console {
        background-color: #5454D4;
      }
      .finais-pc {
        background-color: #71A84A;
      }
      .finais-console {
        background-color: #E4D126;
      }
    }
  }
  
  @media (min-width: 767px) {
    .calendario-titulo {
      font-size: 150px;
    }
  }
}
</style>
