<template>
  <div class="home" id="home">
    <div class="container position-relative">
      <div class="row">
        <div class="col-12">
          <Header></Header>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-8 col-xl-6">
          <div class="hashtag pt-5" id="hashtag-home">
            #FLAKESPOWERCUP
          </div>
          <div class="campeonato-titulo" id="titulo-home">
            O PRIMEIRO CAMPEONATO OFICIAL DO FLAKES POWER!
          </div>
          <div class="campeonato-sobre"  id="description-home">
            Fala ai pessoal, beleza? Tô animado demais, de trazer mais essa novidade para vocês: O Flakes Power Cup! Que vai acontecer tanto para console quanto para PC. Ainda mais porque serão 40 mil dólares em premiação pros melhores colocados. O Flakes Power Cup chegou chegando, e aqui montamos um hub para vocês terem acesso a todas as informações do campeonato: livro de regras, datas e tudo que vocês precisam para participar. O campeonato será em trios então já chama logo seus amigos e comecem a treinar!
          </div>
          <div id="acompanhe-home">
            <div class="acompanhe pt-4">
              ACOMPANHE TUDO SOBRE <br />A FLAKES POWER CUP
            </div>
            <div class="socials d-flex py-3">
              <a href="https://twitter.com/flakespowercup" target="_blank" rel="noopener noreferrer">
                <font-awesome-icon :icon="['fab', 'twitter']" />
              </a>
              <a href="https://www.instagram.com/flakespowercup/" target="_blank" rel="noopener noreferrer">
                <font-awesome-icon :icon="['fab', 'instagram']" />
              </a>
              <a href="https://www.youtube.com/user/flakestwitch" target="_blank" rel="noopener noreferrer">
                <font-awesome-icon :icon="['fab', 'youtube']" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flakes-socials d-none d-md-flex" id="flakes-power">
        <div class="text">@FLAKESPOWER</div>
        <div class="socials-flakes d-flex">
          <a href="https://twitter.com/flakespower" target="_blank" rel="noopener noreferrer">
            <font-awesome-icon :icon="['fab', 'twitter']" />
          </a>
          <a href="https://www.instagram.com/flakespower" target="_blank" rel="noopener noreferrer">
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
          <a href="https://www.youtube.com/user/flakestwitch" target="_blank" rel="noopener noreferrer">
            <font-awesome-icon :icon="['fab', 'youtube']" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'

export default {
  name: 'Home',
  components: {
    Header
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home {
  padding: 90px 0;
  background: url(../assets/home-bg.jpg);
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  .hashtag {
    color: #F56D5A;
    font-size: 20px;
    font-weight: 800;
  }
  .campeonato-titulo {
    color: #FFFFFF;
    font-size: 85px;
    font-weight: 800;
    font-family: "Morganite";
    line-height: 100%;
  }
  .campeonato-sobre {
    color: #FFFFFF;
    font-size: 20px;
  }
  .acompanhe {
    color: #0ED5E1;
    font-size: 20px;
    font-weight: 800;
    line-height: 120%;
  }
  .socials a{
    font-size: 30px;
    margin-right: 25px;
    color: #FFFFFF;
    transition: .3s;
    opacity: .7;
    &:hover {
      opacity: 1;
    }
  }

  .flakes-socials {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 17px;
    background-color: #3c373a;
    position: absolute;
    padding: 10px 15px;
    right: 15px;
    .text {
      color: #00D3FF;
      font-size: 20px;
      font-weight: 800;
    }
    a{
      font-size: 21px;
      margin-left: 20px;
      color: #FFFFFF;
      transition: .3s;
      opacity: .7;
      &:hover {
        opacity: 1;
      }
    }
  }
  
  @media (min-width: 767px) {
    background-position: top center;
    .campeonato-titulo {
      font-size: 94px;
    }
    .campeonato-sobre {
      color: #FFFFFF;
      font-size: 30px;
    }
  }
  
  @media (min-width: 1920px) {
    .flakes-socials {
      right: 0;
    }
  }
}
</style>
