<template>
  <div class="formato" id="formato">
    <img src="../assets/formato.png" alt="Formato BG" class="img-fluid d-none d-xl-block min-100vw">
    <img src="../assets/formato-mobile.png" alt="Formato BG" class="img-fluid d-block d-xl-none min-100vw">
  </div>
</template>

<script>
export default {
  name: 'Formato'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.min-100vw {
  min-width: 100vw;
}
</style>
