<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center pt-5">
          <strong class="patrocinio">PATROCÍNIO</strong>
        </div>
      </div>
      <div class="row py-5 text-center patrocinadores">
        <div class="col-12 col-md-3 patrocinio-wrapper">
          <img src="../assets/itau.png" alt="Itaú logo" class="img-fluid patrocinio-img">
        </div>
        <div class="col-12 col-md-3 patrocinio-wrapper">
          <img src="../assets/ps.png" alt="PlayStation logo" class="img-fluid patrocinio-img">
        </div>
        <div class="col-12 col-md-3 patrocinio-wrapper">
          <img src="../assets/bmw.png" alt="BMW logo" class="img-fluid patrocinio-img">
        </div>
        <div class="col-12 col-md-3 patrocinio-wrapper">
          <img src="../assets/clubedomolho.png" alt="Clube Do Molho logo" class="img-fluid patrocinio-img">
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center footer-align">
          <div class="copyright">© 2021 FLAKES POWER CUP</div>
          <div class="hashtag">#FLAKESPOWERCUP</div>
        </div>
        <!-- <div class="col-12 col-xl-8">
          <span class="footer-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam sit dolor labore</span>
        </div>
        <div class="col-12 col-xl-2">
          <strong class="hashtag">#FLAKESPOWERCUP</strong>
        </div> -->
      </div>
      <div class="row">
        <div class="col-12 text-center py-5">
          <a href="#home">
            <img src="../assets/flakes.png" alt="Flakes logo" class="img-fluid flakes-small">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  background: transparent linear-gradient(180deg, #23284F 0%, #26294F 100%) 0% 0% no-repeat padding-box;
  .patrocinio {
    color: #FFFFFF;
    font-size: 33px;
    font-weight: 800;
  }
  .patrocinio-img {
    margin: 50px;
    max-width: 220px;
    max-height: 80px;
  }
  .copyright {
    color: #FFFFFFB3;
    font-size: 13px;
  }
  .footer-text {
    color: #ffffff;
    font-size: 16px;
  }
  .hashtag {
    color: #9A9CAE;
    font-size: 20px;
    font-weight: 800;
  }
  .flakes-small {
    max-width: 76px;
  }
  .footer-align {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .patrocinadores {
    color: #FFF;
    font-size: 34px;
    font-weight: 600;
  }

  .patrocinio-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media (min-width: 767px) {
  }
}
</style>
