<template>
  <div class="regras" id="regras">
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-4 offset-xl-8">
          <img src="../assets/flakes-power-cup.png" alt="Flakes Fortnite logos" class="img-fluid" id="regras-logo">
          <div class="my-5" id="btn-regras">
            <a href="https://www.epicgames.com/fortnite/competitive/pt-BR/rules-library" target="_blank" class="btn-regras">Livro de Regras</a>
          </div>
          <div id="acompanhe-regras">
            <div class="acompanhe">
              ACOMPANHE TUDO SOBRE <br />A FLAKES POWER CUP
            </div>
            <div class="socials d-flex py-3">
              <a href="https://twitter.com/flakespowercup" target="_blank" rel="noopener noreferrer">
                <font-awesome-icon :icon="['fab', 'twitter']" />
              </a>
              <a href="https://www.instagram.com/flakespowercup/" target="_blank" rel="noopener noreferrer">
                <font-awesome-icon :icon="['fab', 'instagram']" />
              </a>
              <a href="https://www.youtube.com/user/flakestwitch" target="_blank" rel="noopener noreferrer">
                <font-awesome-icon :icon="['fab', 'youtube']" />
              </a>
            </div>
            <div class="hashtag pb-5">
              #FLAKESPOWERCUP
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gradiente-divisao"></div>
  </div>
</template>

<script>
export default {
  name: 'Regras'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.regras {
  background-image: url(../assets/regras-bg.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  padding-top: 214px;
  padding-bottom: 246px;
  text-align: center;
  position: relative;
  .acompanhe {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 800;
    line-height: 120%;
  }
  .hashtag {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 800;
  }
  .socials {
    justify-content: center;
    a{
      font-size: 30px;
      margin-right: 25px;
      color: #FFFFFF;
      transition: .3s;
      opacity: .7;
      &:hover {
        opacity: 1;
      }
    }
  }
  .gradiente-divisao {
    background-image: linear-gradient(to top, rgba(35,40,79,1), rgba(0,0,0,0));
    display: block;
    width: 100vw;
    height: 100px;
    position: absolute;
    bottom: 0;
  }
  @media (min-width: 1200px) {
    background-position: top;
    text-align: left;
    .socials {
      justify-content: flex-start;
    }
  }
}
</style>
