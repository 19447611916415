<template>
  <div class="header">
    <div class="row">
      <div class="col-6 col-xl-8 menu-logo">
        <a href="#home">
          <img src="../assets/flakes.png" alt="Flakes logo" class="img-fluid" id="flakes-logo">
        </a>
      </div>
      <div class="col-6 col-xl-4">
        <ul class="menu">
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#formato">Formato</a>
          </li>
          <li>
            <a href="#calendario">Calendário</a>
          </li>
          <li>
            <a href="#regras">Regras</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  .menu-logo {
    text-align: center;
  }
  .menu {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    li {
      list-style: none;
      margin: 5px 0;
      text-align: center;
    }
    a {
      color: #FFFFFF;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      transition: .3s;
      &:hover {
        opacity: .5;
      }
    }
  }
  
  @media (min-width: 768px) {
    .menu-logo {
      text-align: left;
    }
    .menu {
      flex-direction: row;
      li {
        margin: inherit;
        text-align: right;
      }
    }
  }
}
</style>
